.App {
  margin: 20px 20px -50px 20px;
  min-height: 100%;
}

html, body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  height: 50vh;
  display: flex;
}